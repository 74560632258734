.form-search {
    margin-bottom: 40px;

    label {
        width: 0;
        height: 0;
        overflow: hidden;
        display: block;
    }
    input {
        @extend .form-control;
        background-image: url(../images/icon-search.svg);
        background-position: 20px center;
        background-repeat: no-repeat;
        padding-left: 60px;
    }
    button {
        display: none;
    }
}

.search-results {
    @include bg-pattern();
    padding: 20px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    margin: 40px 0;

    span {
        display: inline-block;
        vertical-align: middle;
    }

    strong {
        color: $color-main;
        vertical-align: middle;
        display: inline-block;
        max-width: 280px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}