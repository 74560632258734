.about-us-boxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-bottom: 60px;
    padding: 40px 0;
}
.about-us-box {

}
.about-us-box-img {
}
.about-us-box-title {
    font-weight: 600;
    color: $color-title;
}
.about-us-box-content {
    font-size: 15px;
}

@media (max-width: 680px) {
    .about-us-boxes {
        grid-template-columns: 1fr;
    }
    .about-us-box-img {
        height: 100%;
        object-fit: cover;
    }
    .about-us-box-title {
        margin-top: 0;
    }
    .about-us-box {
        display: grid;
        grid-template-columns: 200px 1fr;
        gap: 20px;
    }
}
@media (max-width: 560px) {
    .about-us-box {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .about-us-box-img {
        width: 100%;
        height: auto;
    }
}


// team member
.team-member {
    display: flex;
    background: #fff;
    box-shadow: 0 3px 10px rgba(#000, 0.2);
    overflow: hidden;
    margin-top: 30px;
    margin-bottom: 30px;
}
.team-member-side {
    width: 330px;
    background: $color-gray;
    @include bg-pattern();
    flex-shrink: 0;
}
.team-member-img {
    border-right: 5px solid $color-main;

    img {
        display: block;
        width: 100%;
    }
}

.team-member-content {
    padding: 40px;
    font-size: 15px;
}
.team-member-name {
    margin-top: 0;
    color: $color-title;
}
.team-member-position {
    color: #999;
    font-style: italic;
    margin-bottom: 20px;
    border-left: 2px solid #ddd;
    padding-left: 20px;
}
.team-member-text {
    line-height: 1.5;
}

@media (max-width: 720px) {
    .team-member {
        flex-direction: column;
    }
    .team-member-side {
        width: 100%;
    }
    .team-member-img {
        display: flex;
        justify-content: left;
        border: 0;
    }
}