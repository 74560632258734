.post-comments > .comment-respond > .comment-form {
    // @extend .card;
    // padding: 30px;
}
.comment-reply-title {
    @extend .p-subtitle;
}
.comment-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    p {
        margin: 0;
    }

    .comment-notes {
        grid-column: span 2;
    }

    .comment-form-comment {
        grid-column: span 2;
    }

    .comment-form-author {
        grid-column: span 1;
    }

    .comment-form-email {
        grid-column: span 1;
    }

    .comment-form-cookies-consent {
        grid-column: span 2;
    }

    @media (max-width: 860px) {
        grid-template-columns: 1fr;

        .comment-notes,
        .comment-form-comment,
        .comment-form-author,
        .comment-form-email,
        .comment-form-cookies-consent {
            grid-column: span 1;
        }
    }

    textarea {
        @extend .form-control-textarea;
        min-height: 100px;
        height: 150px;
    }
    input[type="text"],
    input[type="email"],
    input[type="url"] {
        @extend .form-control;
        height: 50px;
    }

    label {
        font-size: 14px;
        color: #777;
    }

    input[type="checkbox"] {
        position: absolute;
        opacity: 0;
    }
    input[type="checkbox"] + label {
        display: flex;

        span {
            vertical-align: middle;
            width: 24px;
            height: 24px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            margin-right: 10px;
            border-radius: 4px;
            background: #eaeaea;
        }
    }
    input[type="checkbox"]:focus + label {
        span {
            border-color: $color-main;
            outline: none;
            box-shadow: inset 0 0 0 2px $color-main;
        }
    }
    input[type="checkbox"]:checked + label {
        span {
            background-color: $color-main;
            background-image: url(../images/check-fill.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 20px 20px;
        }
    }

    // input[type="submit"],
    // input[type="button"] {
    //     @extend .button;
    // }
}

.comments-list-cnt {
    margin-top: 60px;
}
.comments-list-title {
    @extend .p-subtitle;
}
.comments-list {
    list-style: none;
    padding: 0;
    margin: 30px 0;

    ul {
        list-style: none;
        padding: 0;
        padding-left: 110px;

        @media (max-width: 860px) {
            padding-left: 30px;
        }

        @media (max-width: 680px) {
            padding-left: 0;
        }
    }

    .comment {
        // border-bottom: 2px solid #eee;
        // margin-bottom: 40px;

        .comment { //comment in comment
            margin-bottom: 0;
            border: 0;
        }
    }

    .comment-author-admin {
        .comment-author-img img {
            border: 3px solid $color-main;
        }
    }

    .comment-data {
        font-size: 13px;
        color: #aaa;

        a {
            color: inherit;
        }
    }
    .comment-author-img {
        margin-right: 30px;
        flex-shrink: 0;

        img {
            border-radius: 4px;
            width: 80px;
            height: 80px;
            background: #fefefe;
        }
    }
    .comment-reply-link {
        // @extend .button;
        // padding: 5px 10px;
        // font-weight: 400;
        font-size: 13px;
        // min-width: 50px;
    }
    .comment-author-name {
        color: $color-title;
        font-weight: bold;

        a {
            color: inherit;
        }
    }
    .comment-body {
        display: flex;
        margin-bottom: 30px;
    }
    .comment-author {
        margin-right: 30px;
        flex-shrink: 0;
    }
    .comment-text {
        font-size: 14px;
    }
    .comment-edit-link {
        font-size: 13px;
    }
    .comment-awaiting-moderation {
        @include bg-pattern();
        padding: 10px 20px;
        display: block;
        border-radius: 5px;
    }
}

.comments-list .comment-respond {
    @extend .card;
    position: relative;
    margin-left: 110px;
    margin-bottom: 50px;
    border-radius: 4px;
    padding: 30px;

    @media (max-width: 860px) {
        margin-left: 30px;
    }

    @media (max-width: 680px) {
        margin-left: 0;
    }

    .comment-notes {
        font-size: 15px;
    }

    .comment-reply-title {
        font-size: 20px;
        padding-right: 40px;

        a {
            width: 40px;
            height: 40px;
            position: relative;
            background: none;
            border: 0;
            cursor: pointer;
            overflow: hidden;
            text-indent: -9999px;
            position: absolute;
            right: 20px;
            top: 20px;
            border: 1px solid #888;
            border-radius: 50%;

            &::before,
            &::after {
                content: "";
                width: 15px;
                height: 3px;
                background: #888;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
            }
            &::after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &:hover {
                border: 1px solid #999;

                &::before,
                &::after {
                    background: #999;
                }
            }
        }
    }

    label {
        font-size: 14px;
    }

    textarea {
        min-height: 100px;
    }
}