.form {}
.form-row{
    margin-bottom: 23px;
}
.form-row-hidden {
    display: none;
}
.form-label {
    display: block;
    font-weight: 400;
    margin-bottom: 5px;
}
.form-control {
    height: 58px;
    background: #eaeaea;
    border:0;
    padding: 0 15px;
    width: 100%;
    box-sizing: border-box;
    font-size: inherit;
    font-family: inherit;
    border-radius: 5px;

    // background: #fff;
    // border: 1px solid #aaa;


    &:focus {
        border-color: $color-main;
        outline: none;
        box-shadow: inset 0 0 0 2px $color-main;
    }

    &.form-control-error {
        border-color: orangered;
        box-shadow: inset 0 0 0 1px orangered;
    }
}
.form-control-textarea {
    @extend .form-control;
    min-height: 200px;
    max-height: 400px;
    resize: vertical;
    padding: 15px;
}
.form-row-btn {}

@media (max-width: 890px) {
    .form-row-btn {
        text-align: center;
    }
}