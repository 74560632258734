.m-services {
    padding-bottom: 0;
}
.m-service-content {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 0.7fr 1.3fr;
    gap: 50px;
    position: relative;
}
.m-service-img {
    position: relative;

    img {
        max-width: 70vw;
        z-index: -1;
        position: absolute;
        right: -40px;
        bottom: 0;
    }
}

.m-service-list-cnt {
    padding: 20px;
    padding-bottom: 75px;
}

.m-services-list {
    color: #111;
    margin-bottom: 40px;
    max-width: 720px;
    padding: 0;
    margin: 0;
    list-style: none;
    font-weight: 700;
    display: grid;
    grid-template-columns: 0.8fr 1.2fr;
    gap: 25px 30px;
    padding-bottom: 50px;

    li {
        display: flex;
        align-items: center;
        padding-left: 30px;
        background-image: url(../images/icon-small-arrow.svg);
        background-repeat: no-repeat;
        background-position: left center;
    }
}

@media (max-width: 1200px) {
    .m-service-content {
        grid-template-columns: 0.5fr 1.5fr;
    }
    .m-service-img {
        img {
            right: -70px;
        }
    }
}
@media (max-width: 980px) {
    .m-services-container {
        padding: 0;
    }
    .m-service-content {
        grid-template-columns: 1fr;
        gap: 0;
    }
    .m-service-img {
        grid-row: 2 / 3;

        img {
            max-width: 100vw;
            left: 50%;
            transform: translate(-50%);
        }
    }
    .m-service-list-cnt {
        background-color: rgba(#fff, 0.6);
    }
    .m-services-list {
        text-align-last: left;
        justify-content: center;
        max-width: 100%;
        gap: 25px;
        justify-content: center;
        grid-template-columns: repeat(auto-fit, 320px);

        li {
            text-align: left;
        }
    }
    .m-service-list-cnt {
        text-align: center;
    }
}
@media (max-width: 700px) {
    .m-services-list {
        grid-template-columns: repeat(auto-fit, 250px);
    }
    .m-service-img {
        img {
            max-width: 500px;
        }
    }
}

.m-service-list-cnt-mobile {
    display: none;
}

@media (max-width: 750px) {
    .m-service-list-cnt {
        display: none;
    }
    .m-service-list-cnt-mobile {
        display: block;
        text-align: center;
        overflow: hidden;
        width: 100%;
        padding-bottom: 80px;
    }
    .m-service-img {
        display: none;
    }
    .m-services-list-mobile {
        &::before {
            content: "";
            background: url(../images/small-cat.png);
            width: 257px;
            height: 115px;
            position: absolute;
            left: 50%;
            top: -92px;
            transform: translate(-50%);
        }

        position: relative;
        background: #fff;
        color: $color-title;

        border-radius: 5px;
        box-shadow: 0 3px 10px rgba(#000, 0.2);
        border: 2px solid $color-main;
        border-top: 0;
        max-width: 500px;
        width: calc(100% - 30px);
        padding: 20px;
        list-style: none;
        margin: 0 auto;
        margin-top: 100px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 40px;

        li {
            padding: 10px;
            border-bottom: 1px solid rgba(#000, 0.1);

            &:last-child {
                border: 0;
            }
        }
    }
}