//service info
.service-info {
    @extend .card;
    margin-top: 60px;
    min-height: 300px;
    padding: 30px;
    background-image: url(../images/dog-and-cat.jpg);
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) calc(100% - 20px);
    background-size: 470px;
    display: flex;
    flex-direction: column;
    border: 10px solid $color-main;
}
.service-info-title {
    margin-top: 0;
    font-size: 30px;
    color: $color-title;
}
.service-info-text {
    max-width: 700px;
}
.service-info-phone {
    font-weight: bold;
    color: #222;
    font-size: 25px;
    display: flex;
    align-items: center;
    margin-top: 30px;

    span {
        display: block;
        line-height: 1;
    }
    strong, a {
        background: #fff;
        margin-left: 20px;
        border: 1px solid $color-main;
        color: $color-main;
        border-radius: 50px;
        padding: 5px 20px 5px 5px;
        text-decoration: none;
        font-weight: bold;
    }
}
@media (max-width: 940px) {
    .service-info {
        padding-bottom: 260px;
        background-position: center bottom;
    }
}
@media (max-width: 670px) {
    .service-info {
        text-align: center;
    }
    .service-info-phone {
        align-items: center;
        flex-direction: column;

        strong, a {
            margin-top: 20px;
            margin-left: 0;
        }
    }
}