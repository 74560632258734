.contact-cnt {
    display: grid;
    gap: 80px;
    grid-template-columns: 1fr min(350px, 100%);
}

.contact-address {
    max-width: 700px;
    grid-column: 2 / 3;

    address {
        font-size: 16px;
        font-style: normal;
        margin-bottom: 30px;

        h2, h3 {
            margin-top: 0;
            color: $color-title;
        }
    }
}

.contact-form-cnt {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    max-width: 700px;

    .contact-text {
        margin-bottom: 30px;
    }
}

//wysylanie
.contact-form-send {
    @extend .card;
    margin-top: 20px;
    padding: 30px;
    background-image: url(../images/ultra-dog.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    padding-bottom: 140px;
}
.contact-form-send-title {
    @extend .p-subtitle;
    font-size: 30px;
    margin-top: 0;
}
.contact-form-send-text {
    margin-bottom: 30px;
}

@media (max-width: 890px) {
    .contact-cnt {
        grid-template-columns: 1fr;
        gap: 70px;
    }
    .contact-content {
        grid-row: 1 / 2;
        text-align: center;
    }
    .contact-address {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        border-bottom: 3px solid #eee;
        padding-bottom: 70px;
    }
    .contact-form-cnt {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}