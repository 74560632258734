.m-boxes {
    margin-top: -10px;
    padding-bottom: 0px;
}

.m-boxes-container {
    padding-left: 0;
    padding-right: 0;
    max-width: 1200px;
}
.m-box {
    margin: 0 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    height: calc(100% - 20px);
}
.m-box-img-cnt {
    position: relative;

    img {
        display: block;
        width: 100%;
        max-height: 200px;
        object-fit: cover;
    }

    // &::before {
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     bottom: 0;
    //     width: 100px;
    //     height: 3px;
    //     background: $color-main;
    // }
}
.m-box-content {
    margin: 20px;
}
.m-box-title {
    margin-top: 0;
    font-size: 20px;
    font-weight: 500;
}
.m-box-text {
    font-weight: 300;
    color: #777;
}

@media (max-width: 680px) {
    .m-boxes-container {
        flex-direction: column;
    }
}
