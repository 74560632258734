@use "sass:color";

body.popup-is-visible {
    height: 100vh;
    overflow: hidden;

    .all {
        overflow: hidden;
        height: 100vh;
    }
}
.popup {
    $color: color.scale($color-main-dark, $lightness: -40%);
    background: rgba($color, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000000;
}
.popup-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.popup-container-inside {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    min-height: 100%;

    @media (max-width: 600px) {
        padding: 20px 10px;
    }
}
.popup-cnt {
    padding: 30px;
    background: #fff;
    border-radius: 3px;
    width: calc(100% - 40px);
    max-width: 600px;
    min-height: 500px;
    position: relative;
    box-shadow: 0 0 30px rgba(#000, 0.5);

    @media (max-width: 600px) {
        width: calc(100% - 10px);
    }
}
.popup.is-big .popup-cnt {
    max-width: 100%;
}
.popup-close {
    width: 50px;
    height: 50px;
    position: relative;
    background: none;
    border: 0;
    cursor: pointer;
    overflow: hidden;
    text-indent: -9999px;
    position: absolute;
    right: 20px;
    top: 20px;
    border: 1px solid #888;
    border-radius: 50%;

    &::before,
    &::after {
        content: "";
        width: 25px;
        height: 3px;
        background: #888;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:hover {
        border: 1px solid #999;

        &::before,
        &::after {
            background: #999;
        }
    }
}

.popup-title {
}

.popup-content {
    color: #444;

    strong {
        color: $color-title;
    }
}

.popup-text {
    padding: 20px 20px 0;

    @media (max-width: 600px) {
        padding: 0;
    }
}

.popup-bottom {
    text-align: center;
    margin-top: 30px;
}