.m-title {
    text-align: center;
    color: $color-title;
    font-size: 40px;
    // text-transform: uppercase;
}

.m-text {
    max-width: 800px;
    margin: 30px auto;
    text-align: center;
    font-weight: 300;
    line-height: 1.5;
    color: #555;
    font-style: italic;
    padding-left: 30px;
    padding-right: 30px;
}

.m-section {
    padding: 75px 0;
}
