.pagination {
    border-top: 2px solid #ddd;
    padding: 0;
    padding-top: u(50px);
    list-style: none;
    margin: u(50px) 0;
    display: flex;
    flex-wrap: wrap;

    li {
        margin-right: u(20px);
        margin-bottom: u(20px);

        &:last-child {
            margin-right: 0;
        }
    }

    a {
        @extend .button;
    }

    @media (max-width: 650px) {
        justify-content: center;

        li {
            margin-left: u(10px);
            margin-right: u(10px);
        }
    }
}