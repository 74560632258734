@use "sass:color";

body.show-mobile-menu {
    height: 100vh;
    overflow-y: scroll;

    .all {
        height: calc(100vh - 16px);
        overflow-y: hidden;
    }
}

.p-menu-mobile-cnt {
    z-index: 1000;
    position: fixed;
    top:0;
    left: 0;
    width: 290px;
    height: 100vh;
    background: $color-main-dark;
    text-align: center;
    box-shadow: 2px 0 5px rgba(black, 0.5);
    left: -360px;
    transition: 0.5s transform;
}

.p-menu-mobile-bar {
    overflow-y: scroll;
    height: 100vh;

    &::-webkit-scrollbar { width: 1px; }
    &::-webkit-scrollbar-track {}
    &::-webkit-scrollbar-thumb {
        background: #fff;
        height: 100px;
    }
}

.p-menu-mobile-top {
    background: #fff;
    padding: 20px;

    .p-phone-cnt {
        margin-top: 20px;
        padding-top: 10px;
    }

    .p-phone {
        border-radius: 50px;
        display: flex;
        text-align: left;
        margin: 20px 0;
        padding: 10px;
        border: 1px solid $color-main;

        .p-phone-text {
            flex: 1;
            text-align: center;
            padding-right: 20px;
        }
    }
}

.p-menu-mobile-close {
    box-shadow: 2px 0 5px rgba(black, 0.5);
    position: absolute;
    right: -70px;
    top: 0;
    width: 70px;
    height: 70px;
    background: none;
    border: 0;
    background: #222;
    transition: 0.2s;
    cursor: pointer;
    outline: none;

    span {
        display: block;
        text-indent: -999px;
        overflow: hidden;

        &::before,
        &::after {
            content: "";
            width: 30px;
            height: 5px;
            background: #fff;
            border-radius: 1px;
            position: absolute;
            left: 50%;
            top:50%;
            transform: translate(-50%, -50%);
        }

        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &:hover {
        background: #000;
    }
}

.p-menu-mobile-content {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    display: grid;
    grid-template-rows: 100px auto 1fr;
    gap: 30px;

    .p-logo {

    }
}

.p-menu-mobile-list {
    text-align: center;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 60px;
    text-align: center;

    li:first-child {
        a {
            border-top: 1px solid rgba(white, 0.2);
        }
    }

    a {
        display: block;
        text-decoration: none;
        font-weight: bold;
        padding: 15px;
        color: #fff;
        border-bottom: 1px solid rgba(white, 0.2);
        &:hover {
            background: color.scale($color-main, $lightness: -30%);
            color: #fff;
        }
    }
}

.p-menu-mobile-bg {
    z-index: 999;
    position: fixed;
    background: rgba(#444, 0.6);
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(2px) grayscale(1) opacity(0.8);
    display: none;
}

.p-menu-mobile-cnt.show {
    transform: translate(360px);
}
.p-menu-mobile-cnt.show + .p-menu-mobile-bg {
    display: block;
}