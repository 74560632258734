.p-footer {
    background: #F9F9F9;
    padding-bottom: 40px;
    margin-top: 200px;
}
.p-footer-container {
    position: relative;

    &::before {
        pointer-events: none;
        content: "";
        background: url(../images/animals-footer.png);
        background-repeat: no-repeat;
        background-size: cover;
        width: 600px;
        height: 228px;
        position: absolute;
        right: 0;
        top: -179px;
        mix-blend-mode: multiply;
    }
}

@media (max-width: 890px) {
    .p-footer-container {
        &::before {
            transform: translate(-50%);
            left: 50%;
        }
    }
}

@media (max-width: 680px) {
    .p-footer-container {
        &::before {
            width: 500px;
            height: 190px;
            top: -149px;
        }
    }
}

.p-footer-nav {
    border-bottom: 1px solid #EAEAEA;
    padding-top: 20px;

    ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 20px 0 20px;
    }
    a {
        color: #5d5d5d;
        text-decoration: none;
        padding: 15px 20px;
        display: block;
        transition: 0.2s;

        &:hover {
            color: $color-main;
        }
    }
}

@media (max-width: 500px) {
    .p-footer-nav ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));

        li:nth-of-type(2n+1) {
            text-align: right;
        }
    }
}
@media (max-width: 380px) {
    .p-footer-nav ul {
        grid-template-columns: 1fr;

        li, li:nth-of-type(2n+1) {
            text-align: center;
        }
     }
}

.p-footer-bottom {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
}
.p-footer-left {}
.p-footer-address {
    display: flex;
}
.p-footer-address-logo {
    margin-right: 30px;
    max-width: 100px;
}
.p-footer-address-text {
    font-size: 15px;
    line-height: 1.4;
    font-weight: 300;
    font-style: normal;

    h2, h3 {
        color: $color-title;
    }

    a {
        text-decoration: none;
    }
}

.p-footer-social {
    margin-top: 10px;
}
.p-footer-social-icon {
    box-shadow: inset 0 0 0 1px #494949;
    transition: 0.2s;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
        transition: 0.2s;
        fill: #494949;
    }

    &:hover {
        box-shadow: inset 0 0 0 20px #494949;

        svg {
            fill: #fff;
        }
    }
}

.p-footer-copyright {
    text-align: center;
    font-size: 13px;
    color: #ddd;

    a, a:hover {
        color: inherit
    }
}

@media(max-width: 760px) {
    .p-footer-social-icon {
        width: 36px;
        height: 36px;
    }
}

.p-footer-opening {
    font-weight: 300;
    color: #888;

    div {
        margin-bottom: 15px;
        font-size: 15px;
    }

    strong {
        font-size: 17px;
        display: block;
        color: #222;
    }
}

@media(max-width: 760px) {
    .p-footer-bottom {
        flex-direction: column;
        align-items: center;
    }
    .p-footer-address {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 40px;

        img {
            max-width: 100px;
        }
    }

    .p-footer-address-text {
        font-size: 18px;
    }
    .p-footer-address-logo {
        margin-right: 0;
    }

    .p-footer-opening {
        text-align: center;
        font-size: 18px;
    }
}