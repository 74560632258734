@use "sass:color";

.m-banner-team {
    height: 50vw;
    max-height: 700px;
    background: url(../images/banner-main-team.jpg);
    background-position: center top;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #fff;
    background-position: center top;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left:0;
        top: 0;
        width: 100%;
        height: 100%;
        --c1: #{rgba(color.scale($color-main, $lightness: -40%), 0.1)};
        --c2: #{rgba(color.scale($color-main, $lightness: -40%), 0.3)};
        background: linear-gradient(90deg, var(--c2), var(--c1), var(--c2));

    }
}

.m-banner-team-content {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.m-banner-team-title {
    //font-family: 'Amatic SC', cursive;
    font-family: 'Raleway', sans-serif;
    color: #fff;
    font-weight: 200;
    font-size: 8vw;
    text-align: center;
    margin: 10px 0;
    padding: 0 40px;
    margin-bottom: 30px;
}

.m-banner-team-button {
    box-shadow: inset 0 0 0 1px rgba(#fff, 0.7);
    transition: 0.2s;
    color: #fff;
    padding: 20px 40px;
    border-radius: 50px;
    text-decoration: none;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    border: 0;
    background: none;
    cursor: pointer;
    min-width: 200px;
    justify-content: center;
    transition: 0.2s;

    &:hover {
        background: #fff;
        color: $color-main;
    }
}

@media (max-width: 780px) {
    .m-banner-team {
        height: 500px;
    }
    .m-banner-team-title {
        // font-size: 70px;
    }
}
@media (max-width: 500px) {
    .m-banner-team-title {
        font-size: 50px;
    }
}
@media (min-width: 1200px) {
    .m-banner-team-title {
        font-size: 80px;
    }
}