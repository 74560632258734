.posts-list {
    .post-title {
        font-size: u(35px);
        margin-bottom: u(30px);
    }
    .post-meta {
        margin: u(30px) 0;
    }
    .post-bottom-bar .button {
        box-shadow: none;
        padding: 0;
        min-width: 0;

        &:hover {
            background: none;
            color: $color-main;
            box-shadow: none;

            svg {
                fill: $color-main;
            }
        }
    }
}