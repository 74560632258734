@use "sass:color";

@function u($px, $unit: rem, $base: 16px) {
    @return #{$px / $base}#{$unit};
}

@mixin bg-pattern($color: $color-gray, $size: 5px, $darken: -2%) {
    $c1 : $color;
    $c2 : color.scale($color, $lightness: $darken);

    background: $color;
    background-size: $size $size;
    background-image: linear-gradient(
        -45deg,
        #{$c1} 25%,
        #{$c2} 25%, #{$c2} 50%,
        #{$c1} 50%, #{$c1} 75%,
        #{$c2} 75%
    );
}