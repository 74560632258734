.all {
    overflow-x: hidden;
    padding-top: 120px;
}

.title {
    font-size: 50px;
    font-weight: 700;
    color: $color-title;
    font-family: $font-title;
    margin-bottom: 30px;
    margin-top: 0;

    @media (max-width: 600px) {
        font-size: 40px;
    }
}

.text {
    margin-bottom: 30px;
    line-height: 1.5;
    max-width: 700px;
}

.p-title {
    font-size: 40px;
    font-weight: 800;
    color: $color-title;
    font-family: $font-title;
    margin-bottom: 30px;
    margin-top: 0;

    @media (max-width: 600px) {
        font-size: 30px;
    }
}

.p-subtitle {
    @extend .p-title;
    display: flex;
    align-items: center;
    font-size: 35px;
}

.section-gray {
    position: relative;
    margin-bottom: 30px;

    .container {
        padding: 0;
    }

    img {
        display: block;
        width: 100%;
    }

    &::before {
        content: "";
        width: 200vw;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        z-index: -1;
        top: 0;
        height: 100%;

        @include bg-pattern();
    }
}

.card {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgba(#000, 0.1);
    overflow: hidden;
}

.address-cnt {
    address {
        font-style: normal;
        margin-bottom: 40px;

        h2, h3 {
            color: #222;
        }
    }
}

.is-loading {
    position: relative;

    &::before {
        content: "";
        width: 10px;
        height: 10px;
        border: 3px solid rgba(#000, 0.4);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        border-left-color: #000;
        animation: isLoading 0.6s 0s infinite linear;
    }
}

@keyframes isLoading {
    100% {
        transform: translate(-50%, -50%) rotate(1turn);
    }
}


.p-content-cnt {
    margin-top: 40px;
}

.p-content-cnt-with-sidebar {
    display: grid;
    grid-template-columns: 1fr 320px;
    gap: 80px;
    margin-top: 40px;

    .p-sidebar {
    }

    @media (max-width: 1200px) {
        gap: 50px;
        grid-template-columns: 1fr 250px;
    }

    @media (max-width: 960px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        gap: 30px;

        .p-content {

        }
    }
}

.subpage-banner {
    height: 280px;
    background: #ddd;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: left;
    color: #fff;
    background-size: cover;
    background-position: center;
    overflow: hidden;

    &::before {
        content: "";
        left: 50%;
        transform: translate(-50%);
        bottom: 0;
        width: calc(100% + 100px);
        height: 100%;
        box-shadow: inset 0 -2px 30px rgba(#000, 0.1);
        position: absolute;
        z-index: 1;
    }

    &::after {
        content: "";
        z-index: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: inherit;
        background-position: inherit;
        background-image: inherit;
        transform: scale(var(--scale));
    }

    @media (max-width: 600px) {
        height: u(150px);
    }
}



.columns {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;

    .column {
        margin: 0 15px;
        flex: 1;
    }
}

@media (max-width: 680px) {
    .columns {
        flex-direction: column;

        .column {
            margin-bottom: 30px;
        }

        img {
            width: 100%;
        }
    }
}

.space-bottom {
    margin-bottom: 40px;
}