.share-bar {
    margin: u(50px) 0;

    > * {
        margin-right: u(10px);
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }
}
.share-print {
    background: orangered;
    border-radius: 50px;
    height: u(50px);
    width: u(50px);
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
        max-width: 20px;
    }

    &:hover {
        color: #fff;
        box-shadow: 0 0 0 3px rgba(orangered, 0.3);
    }
}
.share-facebook {
    background: #3B5998;
    border-radius: 50px;
    height: u(50px);
    padding: 0 u(50px);
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
        max-width: 20px;
        margin-right: u(10px);
    }

    &:hover {
        color: #fff;
        box-shadow: 0 0 0 3px rgba(#3B5998, 0.3);
    }
}
.share-tweeter {
    background: dodgerblue;
    border-radius: 50px;
    height: u(50px);
    padding: 0 u(50px);
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
        max-width: 20px;
        margin-right: u(10px);
    }

    &:hover {
        color: #fff;
        box-shadow: 0 0 0 3px rgba(dodgerblue, 0.3);
    }
}