.p-phone {
    text-decoration: none;
    color: $color-main;
    display: flex;
    align-items: center;
}
.p-phone-icon {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    border: 2px solid $color-main;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;

    svg {
        transition: 0.2s;
        fill: $color-main;
    }
}
.p-phone-text {
    font-weight: 900;
    font-size: 20px;
    white-space: nowrap;
}

.p-phone:hover {
    color: $color-main;

    .p-phone-icon {
        background: $color-main;

        svg {
            fill: #fff;
        }
    }
}

