.p-header {
    position: fixed;
    background: #fff;
    top:0;
    left:0;
    width: 100%;
    height: 120px;
    transition: 0.1s;
    z-index: 1000;
}

.p-header.is-sticky {
    height: 90px;
    box-shadow: 0 20px 30px 0 rgba(28,9,80,.05);
    z-index: 1000;
}

@media (max-width: 1200px) {
    .p-header,
    .p-header.is-sticky {
        position: absolute;
    }
}

.p-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.p-menu {
    height: 100%;
}
.p-menu-list {
    height: 100%;
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    li {
        height: 100%;
        display: flex;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        text-decoration: none;
        transition: 0.04s;
        color: #222;

        &:hover {
            background: $color-main;
            color: #fff;
        }
    }

    li.current-menu-item a {
        color: $color-main;
        font-weight: bold;

        &:hover {
            color: #fff;
        }
    }
}

.p-menu-toggle {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border:0;
    background: 0;
    background: $color-main;
    border: 0;
    border-radius: 50px;
    transition: 0.2s;
    outline: none;
    cursor: pointer;

    strong {
        width: 0;
        height: 0;
        display: block;
        overflow: hidden;
    }
    span {
        width: 25px;
        height: 4px;
        background: #fff;
        border-radius: 2px;
        margin: 1.5px;
        transition: 0.2s;
    }

    &:hover {
        border: 1px solid $color-main;
        background: transparent;

        span {
            background: $color-main;
        }
    }
}


@media (max-width: 1260px) {
    .p-menu {
        a {
            padding: 0 15px;
        }
    }
}

@media (max-width: 1190px) {
    .p-menu-toggle {
        display: flex;
    }
    .p-header .p-phone {
        display: none;
    }
    .p-menu {
        display: none;
    }
}
