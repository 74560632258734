.m-contact-container {
    max-width: 1000px;
}
.m-contact-content {
    display: grid;
    gap: 100px;
    grid-template-columns: 1fr 300px;
}
.m-contact-address {
    h2, h3 {
        margin-top: 20px;
        color: $color-title;
    }
}

@media (max-width: 890px) {
    .m-contact-container {
        max-width: 700px;
    }
    .m-contact-content {
        grid-template-columns: 1fr;
    }
    .m-contact-address {
        grid-row: 1 / 2;
        text-align: center;
    }
}