.category-list {}
.category-list-title {
    @extend .title;
    text-align: left;
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 0;
    margin-top: 0;
}
.category-list {
    list-style: none;
    padding: 0;

    li {
        margin-bottom: 5px;
    }

    span {
        color: #bbb;
        display: inline-block;
        margin-left: 10px;
        font-size: 0.9em;
    }

    .category-zabiegi {
        color: crimson;

        a {
            color: inherit;
        }
    }
}

@media (max-width: 960px) {
    .category-list {
        display: flex;
        flex-wrap: wrap;

        li {
            padding: 0 10px 5px 0;

            a {
                font-size: 15px;
                border-radius: 50px;
                padding: 5px 20px;
                display: inline-flex;
                border: 2px solid $color-main;

                &:hover {
                    background: $color-main;
                    color: #fff;
                }
            }

            &.category-zabiegi {
                a {
                    border-color: crimson;

                    &:hover {
                        background: crimson;
                    }
                }
            }
        }
    }
}