.gallery-list-text {
    margin: 40px 0;
    max-width: 800px;
}
.gallery-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax( min(#{250px}, 50%), 1fr));
    gap: 2px;
    grid-auto-flow: dense;

    &-small {
        grid-template-columns: repeat(auto-fill, minmax( min(#{200px}, 50%), 1fr));
    }
}

.gallery-list-element {
    position: relative;
    overflow: hidden;
    border-radius: 4px;

    &-big {
        grid-column: span 2;
        grid-row: span 2;
    }

    span {
        position: absolute;
        bottom: 2px;
        left: 2px;
        right: 2px;
        padding: 10px;
        background: rgba($color-main, 0.95);
        color: #fff;
        font-size: 16px;
        border-radius: 2px;
        opacity: 0;
        transition: 0.3s opacity;
    }

    img {
        display: block;
        filter: grayscale(0.1);
        transition: 1s;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.9;

        &:hover {
            filter: grayscale(0) contrast(1.1);
            opacity: 1;
        }
    }

    &:hover {
        span {
            opacity: 1;
        }
    }
}