.page-404 {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.error-404 {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 60px;
}

.error-404-img {
    background: url(../images/pelo-404.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;

    img {
        opacity: 0;
    }

    canvas {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
.error-404-content {
    display: flex;
    justify-items: center;
    flex-direction: column;
    align-items: flex-start;

    h1, h2 {
        color: $color-main;
        font-size: 200px;
        margin: 0;
        line-height: 1;
    }

    p {
        margin: 5px 0 40px 0;
    }
}

@media (max-width: 760px) {
    .error-404 {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        justify-items: center;
        gap: 30px;
    }
    .error-404-img {
        max-width: 300px;
    }
    .error-404-content {
        align-items: center;

        p {
            text-align: center;
            margin: 0 0 30px 0;
        }
    }
}

@media (max-width: 400px) {
    .error-404-content {
        h1, h2 {
            font-size:  150px;
        }
    }
}