.m-banner-animals {
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 60px 0;
    background: #ECECEC;
    position: relative;

    .m-banner-canvas-cnt {
        pointer-events: none;
        max-width: 1382px;
        width: 100%;
        margin: 0 auto;
        background-image: url(../images/main-banner.jpg);
        background-position: center;
        background-size: cover;
    }

    .m-banner-canvas {
        position: relative;
        padding-bottom: 679px / 1382px * 100%;
    }

    canvas {
        background: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 900px) {
    .m-banner {
        .m-banner-canvas {
            padding-bottom: 70%;
        }
    }
}

@media (max-width: 680px) {
    .m-banner {
        height: auto;
        padding: 20px 0;

        .m-banner-canvas-cnt {
            max-width:852px;
            background: none;
        }
        .m-banner-canvas {
            padding-bottom: 672 / 852 * 100%;
            background: url(../images/main-banner-mobile.jpg);
            background-size: cover;
        }
        canvas {
            opacity: 0;
        }
    }

}