.m-team {
    background: $color-gray;
    position: relative;
}
.m-team-container {
    padding-left: 0;
    padding-right: 0;;
}
.m-team-bottom {
    margin-top: 40px;
    text-align: center;
}

.m-team-list-user {
    @extend .card;
    margin: 15px;
    background: #fff;
    border-radius: 5px;
    height: calc(100% - 30px);
}
.m-team-list-user-photo {
    overflow: hidden;

    img {
        width: 100%;
        transition: 2s;
    }
}
.m-team-list-user-photo:hover {
    img {
        transform: scale(1.1);
    }
}
.m-team-list-user-content {
    margin: 30px;
}
.m-team-list-user-name {
    font-weight: 500;
    margin-top: 0;
    color: #222;
}
.m-team-list-user-description {
    font-weight: 300;
}