.button {
    box-shadow: inset 0 0 0 2px $color-main;
    transition: 0.2s;
    color: $color-main;
    padding: 20px 40px;
    border-radius: 50px;
    text-decoration: none;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    border: 0;
    background: none;
    font-size: inherit;
    cursor: pointer;
    min-width: 200px;
    justify-content: center;

    &.button-with-icon {
        padding-right: 30px;
    }

    .button-icon {
        margin-left: 15px;
        transition: 0.2s;
        display: flex;
        align-items: center;
    }

    svg {
        fill: $color-main;
        max-width: 30px;
    }

    .button-icon-str {
        display: flex;
        align-items: center;

        svg {
            fill: none;
            stroke: $color-main;
        }
    }
}

.button:not([disabled]):not(.disabled):hover {
    background: $color-main;
    color: #fff;
    box-shadow: 0 2px 5px rgba($color-main, 0.5);

    svg {
        fill: #fff;
    }

    .button-icon {
        transform: translate(3px);
    }

    .button-icon-str {
        svg {
            fill: none;
            stroke: #fff;
        }
    }
}

.button[disabled],
.button.disabled {
    box-shadow: inset 0 0 0 2px #ddd;
    color: #ddd;
    cursor: default;
}