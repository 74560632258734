@use "sass:color";

* {
    box-sizing: border-box;
}

body {
    font-family: $font-main;
    color: $color-text;
    margin: 0;
}

a {
    color: $color-main;
    text-decoration: none;

    &:hover {
        color: color.scale($color-main, $lightness: -60%);
    }
}

h1, h2, h3, h4, h5, h6 {
    color: $color-title;
}

hr {
    border: 0;
    height: 4px;
    background: #eee;
    margin: 40px 0;
}

img {
    max-width: 100%;
    height: auto;
}

.container {
    max-width: 1350px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    // box-shadow: inset 0 0 0 1px rgba(red, 0.2);
}