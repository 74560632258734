@use "sass:list";
@use "sass:color";

.post {
    margin-bottom: 60px;
}
.post-title {
    margin-top: 0;
    color: $color-title;
    text-align: left;

    a {
        color: inherit;
        text-decoration: none;
    }
}
.post-meta {
    margin: 30px 0;
    color: #999;
    font-size: 0.9em;

    > * {
        margin-right: 20px;
    }
}
.post-single .post-meta {
    border-bottom: 3px solid #eee;
    margin-bottom: 40px;
    padding-bottom: 40px;
}
.post-bottom-bar {
    display: flex;
    justify-content: flex-end;
    margin: 30px 0;
}
.post-content {
    font-size: 18px;
    line-height: 1.8;
    color: #444;

    $titleSize: 40px 35px 35px 25px 25px 25px;

    @for $i from 1 through 6 {
        h#{$i} {
            @extend .title;
            font-size: u( list.nth($titleSize, $i) );
            margin: 40px 0;
        }
    }

    blockquote {
        border-left: 3px solid #ddd;
        margin: 30px 0;
        padding-left: 30px;
        font-style: italic;
    }

    figure {
        margin: 30px 0;
    }

    figcaption {
        font-style: italic;
    }

    figure.alighright {

    }

    strong {
        color: $color-title;
    }

    pre {
        font-size: 1.2em;
        overflow-x: auto;
        max-width: 100%;
        white-space: break-spaces;
    }

    table {
        border-collapse: collapse;
        width: 100%;
        border: 2px solid #ddd;
    }

    table td, table th {
        border: 1px solid #ddd;
        padding: 15px;
    }

    ul:not([class]) {
        list-style: none;
        padding-left: 0;
        margin: 10px 0;

        ol:not([class]) > li {
            padding-left: 40px;
        }

        ul:not([class]) > li {
            padding-left: 30px;
        }

        > li {
            position: relative;
            padding-left: 30px;
            margin-bottom: 7px;

            &::before {
                content: "";
                width: 12px;
                height: 12px;
                background: $color-main;
                position: absolute;
                border-radius: 50%;
                left: 0;
                top: 6px;
            }
        }
    }

    ol:not([class]) {
        list-style: none;
        padding-left: 0;
        margin: 10px 0;
        counter-reset: liNr;

        ol:not([class]) > li {
            padding-left: 40px;
        }

        ul:not([class]) > li {
            padding-left: 30px;
        }


        > li {
            color: inherit;
            position: relative;
            padding-left: 30px;
            margin-bottom: 7px;

            &::before {
                counter-increment: liNr;
                content: counter(liNr) ".";
                color: $color-main;
                font-weight: bold;
                position: absolute;
                border-radius: 50%;
                left: 0;
                top: 0;
            }
        }
    }
}

.wp-block-gallery {
    margin: 30px 0;
    padding: 0;

    .blocks-gallery-grid {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        grid-template-columns: repeat(auto-fill, minmax( min(#{200px}, 50%), 1fr));
        display: grid;
        gap: 2px;
        grid-auto-flow: dense;

        .blocks-gallery-item {
            width: 100%;
        }
    }
    .blocks-gallery-item {
        display: flex;

        figure {
            margin: 0;
            width: 100%;
            height: 100%;
        }

        img {
            display: block;
            filter: grayscale(0.1);
            transition: 1s;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0.9;

            &:hover {
                filter: grayscale(0) contrast(1.1);
                opacity: 1;
            }
        }
    }
}