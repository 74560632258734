.swiper-slider-cnt {
    .swiper-slide {
        height: auto;
    }

    .swiper-pagination {
        position: relative;
        padding: 15px 0;

        .swiper-pagination-bullet {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: none;
            opacity: 1;
            background: #ddd;
            transition: 0.2s;
            display: inline-block;
            margin: 0 5px;

            &:focus {
                outline: none;
            }
        }
        .swiper-pagination-bullet-active {
            background-color: $color-main;
            border: 0;
        }
    }
}

@media (min-width: 880px) {
    .swiper-slider-cnt {
        .swiper-pagination {
            display: none;
        }
    }
}

.swiper-slider-cnt {
    position: relative;

    // @media (max-width: 980px) {
    //     &::before,
    //     &::after {
    //         content: "";
    //         z-index: 10;
    //         width: 30px;
    //         height: calc(100% - 30px);
    //         border-radius: 40px;
    //         box-shadow:
    //             0 0px 2.3px rgba(0, 0, 0, 0.1),
    //             0 0px 5.3px rgba(0, 0, 0, 0.1),
    //             0 0px 9.2px rgba(0, 0, 0, 0.1),
    //             0 0px 14.7px rgba(0, 0, 0, 0.1),
    //             0 0px 22.6px rgba(0, 0, 0, 0.1),
    //             0 0px 35.3px rgba(0, 0, 0, 0.1),
    //             0 0px 117px rgba(0, 0, 0, 0.2);
    //         position: absolute;
    //         left: -30px;
    //         top: 0;
    //     }
    //     &::after {
    //         left: auto;
    //         right: -30px;
    //     }
    // }
}
