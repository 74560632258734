@use "sass:color";

$color-main: #8920CE;
$color-main-dark: color.scale($color-main, $lightness: -20%);
$color-gray: #F9F8FD;
$color-title: #222;
$color-text: #666;

$font-main: 'Open Sans', sans-serif;
$font-title: 'Roboto', sans-serif;

