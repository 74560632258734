.services-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 30px;
}
.services-list-el {
    img {
        width: 100%;
        border-radius: 5px;
        display: block;
        transition: 2s;
    }
}
.service-list-el-img-link {
    overflow: hidden;
    display: block;
    border-radius: 5px;
}
.service-list-el-img-link:hover {
    img {
        transform: scale(1.1);
    }
}
.services-list-el-content {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
}
.service-list-el-title {
    margin: 0;
    font-weight: 600;
    color: $color-title;
}
.service-list-el-link {
    font-size: 15px;
    display: flex;
    line-height: 1;
    align-items: center;

    svg {
        max-width: 10px;
        fill: $color-main;
        margin-left: 5px;
    }

    &:hover {
        color: $color-main;
    }
}

